import Head from "next/head"
import { Fragment, useEffect } from "react"
import { useRouter } from "next/router"
import PageImage from "../../assets/images/og_default.jpg"

export interface IPageHeadProps {
  title: string
  description: string
  children?: React.ReactNode
  richSnippets?: any[]
  scripts?: any
  customScript?: string
}

const imagePaths: { [key: string]: string } = {
  "/apartment-moves":
    "https://a-us.storyblok.com/f/1002522/1388x1714/9f4e2aa7e9/apartment-hero.png/m/",
  "/shipping-large-items":
    "https://a-us.storyblok.com/f/1002522/2082x2571/ca5aef1aa5/large-items-hero.png/m/",
  "/package-delivery":
    "https://a-us.storyblok.com/f/1002522/1412x1668/1f09dc40ee/package-delivery.png/m/",
  "/shipping-furniture":
    "https://a-us.storyblok.com/f/1002522/1388x1714/9f4e2aa7e9/apartment-hero.png/m/",
  "/pet-transportation":
    "https://citizenshipper.com/images/dog-hero-one@3x.jpg",
  "/cost-to-ship":
    "https://citizenshipper.com/_next/image?url=%2Fimages%2Fboy-dog-polaroid.webp&w=828&q=75",
  "/car-shipping":
    "https://a-us.storyblok.com/f/1002522/1388x2013/fc5eb96ee7/car-shipping-hero-image.png/m/",
  "/classic-car-transport":
    "https://a-us.storyblok.com/f/1002522/394x592/0b349c470d/classiccars.jpg/m/",
  "/affiliate-program":
    "https://a-us.storyblok.com/f/1002522/394x592/bbe91e8c74/hero-image.webp",
  "/motorcycle-carriers":
    "https://citizenshipper.com/_next/image?url=/_next/static/media/motorcycle_shipping.632edca1.jpg&w=1080&q=75",
  "/ltl-freight-shipping":
    "https://a-us.storyblok.com/f/1002522/1388x1714/1ae15b739c/ltl-freight-shipping.png/m/",
  "/rv-transport":
    "https://a-us.storyblok.com/f/1002522/1388x2013/3adb2f5d84/rv-hero.png/m/",
  "/dog-transportation":
    "https://a-us.storyblok.com/f/1002522/557x707/6550996999/dog-transport-hero.png/m/",
  "/cat-transportation":
    "https://a-us.storyblok.com/f/1002522/1117x1414/9b5d2f9189/cats2.png/m/",
  "/how-it-works":
    "https://citizenshipper.com/_next/image?url=https://a-us.storyblok.com/f/1002522/394x592/bbe91e8c74/hero-image.webp/m/&w=3840&q=75",
  "/pet-shipping/":
    "https://citizenshipper.com/_next/image?url=/images/puppy-marketplace.webp&w=1080&q=75",
  "/freight-shipping-quotes":
    "https://a-us.storyblok.com/f/1002522/1388x1714/efcdb438c9/freight-hero.png/m/",
  "/household-goods-shipping":
    "https://a-us.storyblok.com/f/1002522/1412x1668/0bcc7005aa/household-hero.png/m/",
  "/new-drivers":
    "https://a-us.storyblok.com/f/1002522/760x1100/d23bc281ab/new-drivers-hero-2x.webp",
  "/pallet-shipping":
    "https://a-us.storyblok.com/f/1002522/1388x1714/6b3c651707/pallet-shipping.png/m/",
  "/boat-transportation":
    "https://a-us.storyblok.com/f/1002522/1388x2013/7a4bf9c8a9/boat-transportation.webp",
  "/motorcycle-transportation":
    "https://citizenshipper.com/_next/image?url=https://a-us.storyblok.com/f/1002522/1388x1714/ef49f6dae5/motorcycle-shipping-hero-image.webp&w=3840&q=75",
  "/vehicle-transportation":
    "https://a-us.storyblok.com/f/1002522/366x550/7d72da69c2/vehicle-transportation-hero2.png/m/",
  "/equipment-shipping":
    "https://a-us.storyblok.com/f/1002522/1388x1714/40872aa42b/equipment-hero.png/m/",
  "/referral-program": "https://citizenshipper.com/images/referral-hero.webp",
}

export default function PageHead({
  title,
  description,
  children,
  scripts,
  customScript,
  richSnippets = [],
}: IPageHeadProps) {
  const { asPath } = useRouter()

  const ogImageUrl = imagePaths[asPath] || PageImage.src

  const fullUrl = new URL(`https://citizenshipper.com${asPath}`)
  const canonicalUrl = `${fullUrl.origin}${fullUrl.pathname}`

  useEffect(() => {
    let head = document.head || document.getElementsByTagName("head")[0]
    if (customScript) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(customScript, "text/html")
      const metaElement = doc.head.querySelector("meta")
      if (metaElement) {
        head.appendChild(metaElement)
      }
    }
    if (scripts) {
      let parser = new DOMParser()
      let doc = parser.parseFromString(scripts, "text/html")
      doc.querySelectorAll("script").forEach((scriptTag, index) => {
        let script = document.createElement("script") as HTMLScriptElement

        script.id = `storyblok-custom-script-${index}`
        // @ts-ignore
        script.text = scriptTag.textContent
        head.appendChild(script)
      })

      doc.querySelectorAll("meta").forEach((metaTag) => {
        let meta = document.createElement("meta")
        Array.from(metaTag.attributes).forEach((attr) => {
          meta.setAttribute(attr.name, attr.value)
        })
        head.appendChild(meta)
      })

      doc.querySelectorAll("link").forEach((linkTag) => {
        let link = document.createElement("link")
        Array.from(linkTag.attributes).forEach((attr) => {
          link.setAttribute(attr.name, attr.value)
        })
        head.appendChild(link)
      })
    }
  }, [scripts, customScript])

  return (
    <Head>
      <meta name="description" content={description} />
      <meta name="image" content={ogImageUrl} />
      <meta itemProp="name" content={title} />
      <meta itemProp="image" content={ogImageUrl} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content="@CitizenShipper" />
      <meta name="twitter:image:src" content={ogImageUrl} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:image" content={ogImageUrl} />
      <meta name="og:url" content={`https://citizenshipper.com${asPath}`} />
      <meta name="og:site_name" content="CitizenShipper" />
      <meta name="og:type" content="website" />
      <meta name="theme-color" content="#1B53E1" />
      <title>{title}</title>
      <link rel="icon" type="image/x-icon" href="/favicon.ico" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.webp"
      />
      <link
        rel="android-chrome"
        sizes="192x192"
        href="/android-chrome-192x192.webp"
      />
      <link
        rel="android-chrome"
        sizes="512x512"
        href="/android-chrome-512x512.webp"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.webp"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.webp"
      />
      <link rel="canonical" href={canonicalUrl} />
      <link rel="manifest" href="/manifest.json" />
      {richSnippets.map((snippet, index) => (
        <Fragment key={index}>{snippet}</Fragment>
      ))}
      {children}
    </Head>
  )
}
