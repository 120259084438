import { IButtonProps } from "."

export default function PrimaryButton(props: IButtonProps) {
  const { children, className, ...HTMLButtonProps } = props

  return (
    <button
      {...HTMLButtonProps}
      className={
        "transition-colors text-lg sm:text-xl lg:text-2xl text-center font-roboto font-normal align-middle pointer-cursor px-2 sm:px-4 lg:px-8 py-3 lg:py-3.5 rounded-md bg-blue8 hover:bg-blue7 text-white active:ring " +
        " " +
        className
      }
    >
      {children}
    </button>
  )
}
