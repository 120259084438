import {
  useStoryblokState,
  getStoryblokApi,
  StoryblokComponent,
  storyblokInit,
  apiPlugin,
} from "@storyblok/react"

import components from "../storyblok"
import PageHead from "../components/head/PageHead"
import { env } from "../config"
import { homeSchema } from "../seo/schemas"

storyblokInit({
  accessToken:
    env("STORYBLOK_API_KEY") || process.env.NEXT_PUBLIC_STORYBLOK_API_KEY,
  use: [apiPlugin],
  apiOptions: {
    region: "us",
  },
  components,
})

export default function Page({ story }: any) {
  story = useStoryblokState(story, {})
  const { content } = story

  return (
    <>
      <PageHead
        title={content.title}
        description={content.description}
        richSnippets={[homeSchema]}
      >
        {content.richSnippets?.map((item: { content: any }, n: number) => (
          <script
            key={`application/ld+json${n}`}
            id={`application/ld+json${n}`}
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: item.content,
            }}
          />
        ))}
        <meta name="x:creator:id" content="1467726470533754880" />
      </PageHead>
      <StoryblokComponent blok={content} />
    </>
  )
}

export async function getServerSideProps({ preview }: any) {
  try {
    let sbParams = {
      version:
        env("STORYBLOK_ENV") === "published"
          ? ("published" as const)
          : ("draft" as const),
    }

    if (preview) {
      sbParams.version = "draft" as const
    }

    const storyblokApi = getStoryblokApi()

    if (!storyblokApi) {
      throw new Error("Storyblok API could not be initialized")
    }
    let { data } = await storyblokApi?.get("cdn/stories/index", sbParams)

    return {
      props: {
        story: data ? data.story : false,
        key: data ? data.story.id : false,
        preview: preview || false,
      },
    }
  } catch (error) {
    console.log(error, "Storyblok API error")
    return {
      notFound: true,
    }
  }
}
