import * as React from "react"
import classNames from "classnames"
import { RxEnvelopeClosed } from "react-icons/rx"

type EmailProps = {
  value: string
  onChange: (value: string) => void
  label: string
  error?: string
  id?: string
}

export const Email = React.forwardRef<HTMLInputElement, EmailProps>(
  function EmailInput(props: EmailProps, ref) {
    return (
      <div>
        <div className="relative">
          <label
            className={classNames(
              "text-gray16 uppercase flex pb-4 text-sm lg:text-base",
              props.error != null && "text-red-500",
            )}
            htmlFor={props.id}
          >
            <span className="pr-2">
              <RxEnvelopeClosed size={24} className="text-gold" />
            </span>

            {props.label}
          </label>
          <input
            type="email"
            ref={ref}
            className={classNames(
              "p-4 w-full relative text-base bg-gray50 h-[56] focus:outline-none",
              props.error != null
                ? "border-red-500 text-red-500"
                : "text-neutral-600 border-black border-b",
            )}
            value={props.value}
            onChange={(event) => props.onChange(event.target.value)}
            aria-label="Email"
            id={props.id}
          />
        </div>
        {props.error != null && (
          <span className="text-red-500 text-sm block">{props.error}</span>
        )}
      </div>
    )
  },
)
