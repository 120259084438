"use client"
import * as React from "react"
import PlacesAutocomplete, {
  getLatLng,
  geocodeByPlaceId,
  Suggestion,
} from "react-places-autocomplete"
import composeRefs from "@seznam/compose-react-refs"
import { GoArrowUpRight, GoArrowDownRight } from "react-icons/go"

import classNames from "classnames"

import PoweredByGoogle from "../assets/images/powered-by-google.webp"

type Coordinates = {
  lat: number
  lng: number
}

const locationContainsZipCode = (
  results: google.maps.GeocoderResult[] | null,
) => {
  let contains = false
  if (results) {
    results.forEach((result) => {
      const zipCode = getAddressProperty(
        result.address_components,
        "postal_code",
        "long_name",
      )
      if (zipCode !== null) {
        contains = true
      }
    })
  }

  return contains
}

const locationContainsStateCode = (locations: google.maps.GeocoderResult[]) => {
  return locations.some(
    (location) =>
      location.address_components.find((component) =>
        component.types.includes("administrative_area_level_1"),
      ) != null,
  )
}

// Response from google comes with many variants, we need to find zip variant and return that one, otherwise first variant is returned
const getResultWithZipCode = (results: google.maps.GeocoderResult[]) => {
  const resultItem = results.find((result) => {
    const zipCode = getAddressProperty(
      result.address_components,
      "postal_code",
      "long_name",
    )

    const isUSA = result.address_components.find((component) => {
      return component.short_name === "US"
    })

    return zipCode !== null && isUSA != null
  })

  if (resultItem) {
    return resultItem
  }

  return results[0]
}

const getResultWithMostData = (results: google.maps.GeocoderResult[]) => {
  return results.reduce((accumulator, current) => {
    if (
      current.address_components.length > accumulator.address_components.length
    ) {
      return current
    } else {
      return accumulator
    }
  }, results[0])
}

const getAddressProperty = (
  addressComponents: google.maps.GeocoderAddressComponent[],
  addressType: string,
  property: "long_name" | "short_name",
) => {
  const matchingComponents = addressComponents.filter((cmp) =>
    cmp.types.includes(addressType),
  )

  // NOTE: What if we have more than one matching property?
  if (matchingComponents.length > 0) {
    return matchingComponents[0][property] || null
  }

  return null
}

const getCityName = (
  addressComponents: google.maps.GeocoderAddressComponent[],
) => {
  const locality = addressComponents.find((addressComponent) => {
    return addressComponent.types.includes("locality")
  })

  if (locality) {
    return locality.long_name
  }

  // Fallbacks if locality is not found
  const adminLevel1AndPolitical = addressComponents.find((addressComponent) => {
    return includesAll(addressComponent.types, [
      "administrative_area_level_1",
      "political",
    ])
  })

  if (adminLevel1AndPolitical) {
    return adminLevel1AndPolitical.long_name
  }

  const adminLevel3AndPolitical = addressComponents.find((addressComponent) => {
    return includesAll(addressComponent.types, [
      "administrative_area_level_3",
      "political",
    ])
  })

  if (adminLevel3AndPolitical) {
    return adminLevel3AndPolitical.long_name
  }

  return null
}

async function getLocationData(
  coordinates: Coordinates,
  response: google.maps.GeocoderResult[],
): Promise<google.maps.GeocoderResult> {
  try {
    const geocoder = new google.maps.Geocoder()

    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: coordinates }, function (results, status) {
        if (status === "OK") {
          if (results && results.length > 0) {
            if (locationContainsZipCode(results)) {
              const result = getResultWithZipCode(results)
              resolve(result)
            } else if (locationContainsStateCode(results)) {
              resolve(getResultWithMostData(results))
            } else {
              resolve(response[0])
            }
          }
        }

        reject(status)
      })
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export type GoogleAddressData = {
  formatted_address: string
  city: string
  state_code: string
  lat: number
  lng: number
  zip: string | null
}

type PlaceInputAutocompleteProps = {
  value: GoogleAddressData | null
  onChange: (value: GoogleAddressData | null) => void
  onBlur?: () => void
  onFocus?: () => void
  warning?: string
  error?: string
  disabled?: boolean
  placeholder?: string
  searchOptions?: {
    types: string[]
    componentRestrictions: {
      country: string
    }
  }
  addressFormatter?: (data: GoogleAddressData) => string
  className?: string
  clearOnInputChange?: boolean
  allowCityPrediction?: boolean
  testId?: string
  type?: "pickup" | "dropoff"
  id?: string
}

function cityMatchesAddress(city: string, address: string) {
  return address.toLowerCase().includes(city.toLowerCase())
}

function normalizeAddress(address: string) {
  return address
    .toLowerCase()
    .replace("st.", "saint")
    .replace("nyc", "new york")
    .replace("brooklyn", "new york")
}

function extractCity(cityName: string | null, address: string) {
  if (
    cityName !== null &&
    cityMatchesAddress(normalizeAddress(cityName), normalizeAddress(address))
  ) {
    return cityName
  }

  return ""
}

const defaultSearchOptions = {
  types: [],
  componentRestrictions: { country: "us" },
}

export const PlaceInputAutocomplete = /* @__PURE__ */ React.forwardRef<
  HTMLInputElement,
  PlaceInputAutocompleteProps
>(function PlaceInputAutocomplete(props: PlaceInputAutocompleteProps, ref) {
  const [address, setAddress] = React.useState("")
  const [checkOnBlur, setCheckOnBlur] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const suggestionsRef = React.useRef<readonly Suggestion[] | null>(null)

  const {
    allowCityPrediction = false,
    searchOptions = defaultSearchOptions,
  } = props

  React.useEffect(() => {
    if (props.value && props.value.formatted_address) {
      setAddress(props.value.formatted_address)
    } else {
      setAddress("")
    }
  }, [props.value])

  const handleError = () => {
    props.onChange(null)
  }

  const updateAddressValue = (address: string) => {
    if (!checkOnBlur) {
      setCheckOnBlur(true)
    }
    setAddress(address)
  }

  function compareAddressToSuggestions(
    address: string,
    suggestions: readonly Suggestion[] | null,
  ) {
    if (suggestions == null) {
      return false
    }

    const addressMatchedSuggestion = suggestions.find((suggestion) => {
      return suggestion.description.toLowerCase() === address.toLowerCase()
    })

    return addressMatchedSuggestion != null
  }

  const getAddressData = async (address: string, placeId: string) => {
    if (
      suggestionsRef.current &&
      !compareAddressToSuggestions(address, suggestionsRef.current)
    ) {
      return
    }

    try {
      const response = await geocodeByPlaceId(placeId)
      const coordinates = await getLatLng(response[0])
      const locationData = await getLocationData(coordinates, response)

      const cityName = getCityName(locationData.address_components)

      const stateShortName = getAddressProperty(
        locationData.address_components,
        "administrative_area_level_1",
        "short_name",
      )

      const zipCode = getAddressProperty(
        locationData.address_components,
        "postal_code",
        "long_name",
      )

      const routeData = {
        formatted_address: address,
        city: allowCityPrediction
          ? extractCity(cityName, address)
          : cityName || "",
        state_code: stateShortName || "",
        lat: coordinates.lat,
        lng: coordinates.lng,
        zip: zipCode,
      }

      if (props.addressFormatter) {
        routeData.formatted_address = props.addressFormatter(routeData)
      }

      if (props.clearOnInputChange) {
        setAddress("")
      } else {
        setAddress(address)
      }

      props.onChange(routeData)
      setCheckOnBlur(false)
    } catch (error) {
      props.onChange(null)
    }
  }

  const handleBlur = () => {
    inputRef.current?.blur()
    if (checkOnBlur) {
      if (
        compareAddressToSuggestions(address, suggestionsRef.current) &&
        suggestionsRef.current
      ) {
        const placeId = suggestionsRef.current[0].placeId
        getAddressData(address, placeId)
      } else {
        props.onChange(null)
        setAddress("")
      }
    }

    props.onBlur && props.onBlur()
  }

  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={(address) => {
          updateAddressValue(address)
        }}
        onSelect={getAddressData}
        onError={handleError}
        searchOptions={searchOptions}
        highlightFirstSuggestion
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => {
          // Store genereated suggestions, so that the entered text can be matched against those on the enter key and on a blur event
          // Pressing enter key also triggers onSelect event
          suggestionsRef.current = suggestions

          return (
            <div className="relative">
              <label
                className={classNames(
                  "flex uppercase text-gray16 text-base",
                  props.error != null && "text-red-500",
                )}
                htmlFor={props.id}
              >
                <span className="pr-1">
                  {props.type === "pickup" ? (
                    <GoArrowUpRight size={24} className="text-gold" />
                  ) : (
                    <GoArrowDownRight size={24} className="text-gold" />
                  )}
                </span>
                {props.placeholder}
              </label>
              <input
                className={classNames(
                  "w-full p-4 mt-4 focus:outline-none text-sm lg:text-base bg-gray50 h-[56] border-b xl:min-w-[272px]",
                  props.error != null
                    ? "border-red-500 text-red-500"
                    : "text-neutral-600  border-black",
                )}
                {...getInputProps({
                  disabled: props.disabled,
                  onBlur: () => {
                    handleBlur()
                  },
                  onFocus: () => {
                    if (!checkOnBlur && !props.value) {
                      setCheckOnBlur(true)
                    }
                    props.onFocus && props.onFocus()
                  },
                  id: props.id,
                })}
                ref={composeRefs(inputRef, ref)}
                data-testid={props.testId}
              />
              <ul
                className={classNames("pb-0 mb-0", {
                  "absolute left-0 top-full w-full border border-neutral-700 bg-white z-20 px-0":
                    suggestions.length,
                })}
              >
                {suggestions.map((suggestion) => {
                  return (
                    <li
                      {...getSuggestionItemProps(suggestion, {
                        className: classNames(
                          "px-4 py-2.5 text-base cursor-pointer",
                          suggestion.active && "bg-primary-500 text-white",
                        ),
                      })}
                      key={suggestion.placeId}
                      data-testid={suggestion.id}
                    >
                      <span>{suggestion.description}</span>
                    </li>
                  )
                })}

                {suggestions.length > 0 && (
                  <li className={classNames(" py-2 text-right")}>
                    {/*eslint-disable-next-line @next/next/no-img-element*/}
                    <img
                      src={PoweredByGoogle.src}
                      alt="powered by google"
                      className={classNames("inline-block w-28 h-auto")}
                    />
                  </li>
                )}
              </ul>
            </div>
          )
        }}
      </PlacesAutocomplete>
      {props.warning && !props.error && (
        <p
          className={classNames(
            "inline-block text-base leading-none text-secondary-500",
          )}
        >
          {props.warning}
        </p>
      )}
      {props.error != null && (
        <span className="text-red-500 text-sm block">{props.error}</span>
      )}
    </div>
  )
})

export function includesAll<T>(array: T[], elements: T[]): boolean {
  let result = true

  for (const elem of elements) {
    if (!array.includes(elem)) {
      result = false
      break
    }
  }

  return result
}
