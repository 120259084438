import { useRouter } from "next/router"

import { env } from "../config"

export default function useCTARoute() {
  const base = `${env("APP_HOST")}/shipments/new`

  const router = useRouter()

  const ctaRoutes = {
    "/apartment-moves": `${base}/apartment-home-moves`,
    "/car-shipping": `${base}/vehicles/cars`,
    "/household-goods-shipping": `${base}/household-items`,
    "/rv-transport": `${base}/vehicles/rvs`,
    "/motorcycle-transportation": `${base}/motorcycles`,
    "/equipment-shipping": `${base}/heavy-equipment`,
    "/shipping-large-items": `${base}/freight`,
    "/pallet-shipping": `${base}/freight`,
    "/freight-shipping-quotes": `${base}/freight`,
    "/ltl-freight-shipping": `${base}/freight/less-than-truckload`,
    "/shipping-furniture": `${base}/household-items/furniture`,
    "/package-delivery": `${base}/household-items/boxes`,
  }

  return {
    route: ctaRoutes.hasOwnProperty(router.asPath)
      ? // @ts-ignore
        ctaRoutes[router.asPath]
      : base,
  }
}
